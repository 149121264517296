<template>
  <div ref="main" class="m-main-wrap" v-loading="loading.list">
    <div ref="topDom" class="flex-l pb20">
      <div>
        <el-input
          v-model="username"
          class="w200 mr10"
          placeholder="请输入帳號"
          clearable
          @keyup.enter.native="search"
          @clear="search"
        ></el-input>
        <el-button type="primary" @click="search">搜尋</el-button>
        <el-button class="media_upload" type="primary" @click="accountCreate"
          >新增</el-button
        >
      </div>
    </div>
    <el-container>
      <el-main>
        <el-table
          :data="tableData.data"
          style="width: 100%"
          :props="{ value: 'realName', label: 'realName' }"
          :height="tableHeight"
        >
          <el-table-column width="45" label="NO.">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column
            width="120"
            prop="username"
            label="管理員帳號"
          ></el-table-column>
          <el-table-column width="150" label="登錄時間">
            <template slot-scope="scope">
              {{ scope.row.last_login_time | formatTime }}</template
            ></el-table-column
          >
          <el-table-column
            width="240"
            prop="last_login_ip"
            label="登錄IP"
          ></el-table-column>
          <el-table-column label="狀態">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ccc"
                @change="togglePublish(scope.row.status, scope.$index)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="handleEdit(scope.row, scope.$index)"
                >編輯</el-link
              >
              <el-popconfirm
                confirmButtonText="確定"
                cancelButtonText="取消"
                icon="el-icon-info"
                iconColor="red"
                title="確定刪除嗎？"
                @onConfirm="handleDelete(scope.$index)"
              >
                <el-link slot="reference" type="danger" class="ml10"
                  >删除</el-link
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div ref="btmDom" class="flex-r pb10 pt10">
          <el-pagination
            background
            hide-on-single-page
            :page-size="tableData.per_page"
            :current-page="tableData.current_page"
            :total="tableData.total"
            @current-change="changePage"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>
    <!-- 新建账户 -->
    <el-dialog
      width="600px"
      title="新增管理員"
      :append-to-body="true"
      :modal-append-to-body="true"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <div>
        <el-form
          ref="ruleForm"
          :model="form"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item label="管理員帳號" prop="username">
            <el-input
              v-model="form.username"
              placeholder="请输入登录帐号"
            ></el-input>
          </el-form-item>
          <el-form-item label="登錄密碼" prop="password" class="is-required">
            <el-input
              v-model="form.password"
              show-password
              placeholder="请输入登錄密碼"
            ></el-input>
          </el-form-item>
          <el-form-item label="確認密碼" prop="checkPassword" class="is-required">
            <el-input
              show-password
              v-model="form.checkPassword"
              placeholder="请再次確認密碼"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirm" :loading="loading.save"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { calTableHeight } from "@/common/tool"
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("請輸入密碼"))
      } else {
        // if (this.ruleForm.checkPass !== "") {
        //   this.$refs.ruleForm.validateField("checkPass")
        // }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("請再次輸入密碼"))
      } else if (value !== this.form.password) {
        callback(new Error("兩次輸入密碼不一致!"))
      } else {
        callback()
      }
    }
    return {
      username: "",
      //编辑或修改的role表单
      form: {
        username: "",
        password: "",
        checkPassword: "",
        status: "1",
      },
      rules: {
        username: {
          required: true,
          trigger: "blur",
          message: "必填項",
        },
        password: {
          validator: validatePass,
          trigger: "blur",
          message: "必填項",
        },
        checkPassword: {
          validator: validatePass2,
          trigger: "blur",
        },
      },
      type: "create", //edit|create|preview 编辑还是新建|查看
      // 账号列表数据
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      curIndex: 0,
      showDialog: false,
      loading: {
        save: false,
        password: false,
        list: false,
      },
      tableHeight: 400,
    }
  },
  created() {
    this.getAccountList({
      username: "",
    })
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = calTableHeight(this)
    })
  },
  methods: {
    search() {
      this.getAccountList({
        username: this.username,
      })
    },
    async handleDelete(index) {
      let item = this.tableData.data[index]
      if (item.status == 1) {
        this.$message.warning("正常狀態賬號不可刪除，請先封禁")
      } else {
        let res = await this.api.system.deleteAccount(item.id)
        if (res && res.code == 0) {
          this.$message({
            message: "刪除成功",
            type: "success",
          })
          this.tableData.data.splice(index, 1)
        }
      }
    },
    closeDialog() {
      this.loading.save = false
      this.showDialog = false
      this.$refs["ruleForm"].resetFields()
    },
    /**获取账号列表 */
    async getAccountList(
      { username = "", page = 1 } = { username: "", page: 1 }
    ) {
      this.loading.list = true
      let res = await this.api.system.getAccounts({
        username,
        page,
      })
      if (res && res.code == 0) {
        this.tableData = res.data
      }
      this.loading.list = false
    },
    changePage(page) {
      this.getAccountList({
        username: this.username,
        page,
      })
    },
    /**确定保存 */
    confirm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.saveRole()
        }
      })
    },
    async saveRole() {
      this.loading.save = true
      if (this.type == "preview") {
        this.showDialog = false
        return
      }
      let res
      if (this.type == "edit") {
        // 编辑
        res = await this.api.system.editAccount(this.form)
        this.loading.save = false
        if (res && res.code == 0) {
          this.$message({
            message: "賬號修改成功",
            type: "success",
          })
          this.resetAfterSave()
        }
      } else {
        // 新建
        res = await this.api.system.createAccount(this.form)
        this.loading.save = false
        if (res && res.code == 0) {
          this.$message({
            message: "賬號創建成功",
            type: "success",
          })
          this.resetAfterSave()
        }
      }
    },
    resetAfterSave() {
      this.showDialog = false
      this.loading.save = false
      this.getAccountList({
        username: "",
      })
      this.$refs["ruleForm"].resetFields()
    },
    handleEdit(item, index) {
      if (index == undefined) {
        this.type = "preview"
      } else {
        this.type = "edit"
        this.curIndex = index
      }
      this.showDialog = true
      this.form = {
        ...this.form,
        ...item,
      }
    },
    accountCreate() {
      this.showDialog = true
      this.type = "create"
      this.form = {
        username: "",
        password: "",
        checkPassword: "",
        status: "1",
      }
    },
    togglePublish(value, index) {
      if (value == 0) {
        this.unPublish(index)
      } else {
        this.publish(index)
      }
    },
    async unPublish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.system.switchAccount(id, 0)
      if (res && res.code == 0) {
        this.$message({
          message: "賬號封禁成功",
          type: "success",
        })
      }
    },
    async publish(index) {
      let id = this.tableData.data[index].id
      let res = await this.api.system.switchAccount(id, 1)
      if (res && res.code == 0) {
        this.$message({
          message: "賬號解封成功",
          type: "success",
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
